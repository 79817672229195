import React, { Component } from "react";
import {
	Card,
	Col,
	Container,
	Row,
	Button,
	InputGroup,
	InputGroupAddon,
} from "reactstrap";
import _ from "lodash";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
} from "@material-ui/data-grid";

import LegendsComponent from "./LegendsComponent";
import LoaderComponent from "./LoaderComponent";
import Autocomplete from "./Autocomplete";
import Select, { createFilter } from "react-select";
import { commonServices } from "../services";
// import { withStyles } from "@material-ui/core";

// const StyledDataGrid = withStyles({
//     root: {
//         "& .MuiDataGrid-renderingZone": {
//         maxHeight: "none !important"
//         },
//         "& .MuiDataGrid-cell": {
//         lineHeight: "unset !important",
//         maxHeight: "none !important",
//         whiteSpace: "normal"
//         },
//         "& .MuiDataGrid-row": {
//         maxHeight: "none !important"
//         }
//     }
// })(DataGrid);

class TableWithFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			source_type: [],
			_data_sources: [],
			sourceTypeArray: [],
			totalLength: 0,
			filters: [],
			filteredData: [],
			searchValue: "",
			isLoading: false,
			isSourceLoading: false,
			detectionSearch: [],
			searchByDetectionArray: [],
			allDetections: [],
			suggestionsArray: [],
			isFieldsSelected: true,
			isMultiSelect: false,
			pageSize: 10,
		};
		this.multiSelectRef = [];
		this.searchRef = "";
	};
	version = this.props.version;

	clearResults = () => {
		let allDetections = [...this.state.allDetections];
		_.each(this.multiSelectRef, function (selectRef) {
			selectRef.select.clearValue();
		});

		this.searchRef.select.clearValue();
		// this.multiSelectRef.select.clearValue();
		// document.getElementsByClassName('select__indicator').click();
		this.setState({
			sourceTypeArray: [],
			totalLength: allDetections.length,
			filteredData: allDetections,
			searchValue: "",
			isFieldsSelected: true,
			detectionSearch: [],
		});
	};

	setRef = (ref) => {
		this.multiSelectRef.push(ref);
		// console.log('this.multiSelectRef: ', this.multiSelectRef);
	};

	filterResults = () => {
		this.setState({ isLoading: true });
		let sourceTypeArray = [...this.state.sourceTypeArray];
		let detectionSearch = [...this.state.detectionSearch];
		let _data_sources = [...this.state._data_sources];
		let allDetections = [...this.state.allDetections];
		let searchValue = this.state.searchValue;
		let filteredData = [];
		let selectedItemsArray = [];
		if(!_.isEmpty(sourceTypeArray)){
			filteredData = [];
			let itemIds = _.map(sourceTypeArray, "_id");
			selectedItemsArray.push(itemIds);
			selectedItemsArray = _.flatten(selectedItemsArray);
			let seletectedSources = {
				selected_sources: selectedItemsArray,
			};
			// console.log("version:", this.version);
			commonServices.showDetections(seletectedSources, this.version).then((response) => {
				if (response.data.status === "OK") {
					filteredData = response.data.detections;
					this.setState({
						sourceTypeArray,
						filteredData,
						totalLength: filteredData.length,
						isFieldsSelected: false,
						isLoading: false,
					});
				}
			});
		} else if(!_.isEmpty(detectionSearch)){
			filteredData = [];
			_.each(detectionSearch, function(d){
				filteredData.push(_.filter(allDetections, (item) => { return item["detection"] == d.value }));
			});
			filteredData = _.flatten(filteredData);
			this.setState({
				filteredData,
				totalLength: filteredData.length,
				isFieldsSelected: true,
				detectionSearch: detectionSearch,
				isLoading: false,
			});
		} else if(!_.isEmpty(searchValue)){
			filteredData = [];
			// filteredData.push(_.filter(allDetections, (item) => { return item["XDR Event Name"] == searchValue }));
			// filteredData = _.flatten(filteredData);
			commonServices.search(searchValue).then( response => {
                this.setState({ isLoading: false, isMultiSelect: false });
                let filteredData = response.data.detections;
                

                filteredData = _.uniqWith(filteredData, _.isEqual);
                this.setState({
                    filteredData: filteredData,
                    totalLength: filteredData.length,
					searchValue: searchValue,
					isFieldsSelected: true,
					isLoading: false
                });
            });
		}
		
	};

	onSourceChange = (selectedItems, selectBoxName) => {
		let detectionSearch = [...this.state.detectionSearch];
		let allDetections = [...this.state.allDetections];
		let filterData = [...this.state.filteredData];
		if (!_.isEmpty(detectionSearch)) {
			this.searchRef.select.clearValue();
		}
		let isMultiBoxSelected = false;
		const multiSelectBoxes = this.multiSelectRef;
		const scope = this;
		setTimeout(function(){
			let sourceUpdatedArray = [];
			_.each(multiSelectBoxes, function(s){
				if(!_.isEmpty(s.state.value)){
					isMultiBoxSelected = true;
					sourceUpdatedArray.push(s.state.value);
				}
			});
			sourceUpdatedArray = _.flatten(sourceUpdatedArray);
			sourceUpdatedArray = _.uniq(sourceUpdatedArray);

			if (!isMultiBoxSelected) {
				scope.setState({
					sourceTypeArray: [],
					filteredData: allDetections,
					totalLength: allDetections.length,
					searchValue: "",
					isFieldsSelected: true,
					detectionSearch: [],
				});
			} else {
				scope.setState({
					sourceTypeArray: sourceUpdatedArray,
					// filteredData: filteredData,
					// totalLength: filteredData.length,
					searchValue: "",
					isFieldsSelected: false,
					detectionSearch: [],
				});
			}
		}, 400);
	};

	onSearchChange = (userInput) => {
		let searchValue = userInput;
		let filterData = [...this.state.filteredData];
		if (searchValue !== "") {
			_.each(this.multiSelectRef, function (selectRef) {
				selectRef.select.clearValue();
			});
			let scope = this;
			setTimeout(function(){
				scope.setState({
					sourceTypeArray:[],
					// filteredData: filterData,
					// totalLength: filterData.length,
					detectionSearch: [],
					isFieldsSelected: false,
					searchValue
				});
			}, 400);
		}
	};
	onDetectionSearchChange = (selectedItems) => {
		let filterData = [...this.state.filteredData];
		if (!_.isEmpty(selectedItems)) {
			_.each(this.multiSelectRef, function (sel) {
				sel.select.clearValue();
			});
			let scope = this;
			setTimeout(function(){
				scope.setState({
					isFieldsSelected: false,
					detectionSearch: selectedItems,
					sourceTypeArray:[],
					// filteredData: filterData,
					// totalLength: filterData.length,
					searchValue:""
				});
			}, 400);
		}
	};

	renderSourcesSelect() {
		let source_types = [...this.state.source_type];
		let dataSources = [...this.state._data_sources];
		let selectBoxesValues = [];
		source_types = _.orderBy(source_types, ["order"], ["asc"]);
		_.each(source_types, function (source_type) {
			let data = _.filter(dataSources, function (o) {
				return (
					o._source_type_id === source_type._id &&
					((o.label = o.name), (o.value = o._id))
				);
			});
			// console.log("source_type: ", source_type)
			data = _.orderBy(data, ["name"], ["asc"]);
			selectBoxesValues.push(data);
		});
		return selectBoxesValues.map((sourcTypeArray, index) => (
			<Col xs="3">
				<label>
					Select {selectBoxesValues[index][0]._source_type}:
				</label>
				<Select
					isMulti
					name={selectBoxesValues[index][0]._source_type}
					options={sourcTypeArray}
					className="basic-multi-select"
					classNamePrefix="select"
					closeMenuOnSelect={false}
					ref={this.setRef}
					onChange={(e) =>
						this.onSourceChange(
							e,
							selectBoxesValues[index][0]._source_type
						)
					}
				/>
			</Col>
		));
	}

	searchPanel = () => {
		return (
			<div>
				<Card className="panel">
					<Row>
						<Col xs="12">
							<div className="panel-heading">
								Search by Data Source:
							</div>
						</Col>
					</Row>
					<Row>{this.renderSourcesSelect()}</Row>
					<Row>
						<Col xs="12">
							<hr />
						</Col>
					</Row>
					<Row>
						<Col xs="5" className="d-flex align-items-center">
							<div className="panel-label">
								OR Search By Detection Name:{" "}
							</div>
						</Col>
						<Col xs="7">
							<Select
								name="searchByDetection"
								isMulti
								options={this.state.searchByDetectionArray}
								className="basic-multi-select"
								classNamePrefix="select"
								isClearable
								closeMenuOnSelect={false}
								placeholder="Search By Detection Name"
								ref={(ref) => {
									this.searchRef = ref;
								}}
								filterOption={createFilter({
									ignoreCase: true,
									ignoreAccents: true,
									trim: true,
									matchFromStart: false,
								})}
								onChange={this.onDetectionSearchChange}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							<hr />
						</Col>
					</Row>
					<Row>
						<Col xs="5" className="d-flex align-items-center">
							<div className="panel-label">
								OR Search by (Event Type/Source Data Indices):
							</div>
						</Col>
						<Col xs="7">
							<InputGroup>
								<InputGroupAddon
									color="primary"
									addonType="prepend">
									<i className="fa fa-search"></i> Search
								</InputGroupAddon>
								{/* <input value={this.state.searchValue} onChange={this.onSearchChange} className="form-control" /> */}
								<Autocomplete
									showSuggestions={true}
									searchChange={this.onSearchChange}
									clearSearch={this.state.searchValue}
									suggestions={this.state.suggestionsArray}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							<hr />
						</Col>
					</Row>
					<Row>
						<Col
							xs="6"
							className="d-flex align-items-center result-text">
							Showing total results of {this.state.totalLength}{" "}
							detections.
						</Col>
						<Col xs="6">
							<Button
								disabled={this.state.isFieldsSelected}
								className="float-right"
								color="warning"
								onClick={this.filterResults}>
								Show Results
							</Button>
							<Button
								className="float-right mr-2"
								color="info"
								onClick={this.clearResults}>
								Clear Results
							</Button>
						</Col>
					</Row>
				</Card>
				<LegendsComponent/>
			</div>
		);
	};

	componentDidMount() {
		this.props.setVersion(this.version);
		commonServices.getSourceTypes().then((response) => {
			let sourceTypesObject = response.data.source_types;
			// console.log("sourceTypesObject: ", sourceTypesObject);
			this.setState({
				source_type: sourceTypesObject,
			});
			commonServices.getDataSources().then((response) => {
				let dataSourcesObject = response.data.data_sources;
				// console.log("dataSourcesObject: ", dataSourcesObject);
				this.setState({
					_data_sources: dataSourcesObject,
					isSourceLoading: true,
				});
			});
		});

		

		commonServices.getAllDetections(this.version).then((response) => {
			let searchByDetectionArray = [...this.state.searchByDetectionArray];
			let filteredData = [];
			let suggestionsArray = [];
			if (response.data.status === "OK") {
				let allDetections = response.data.detections;
				// console.log(allDetections);
				_.each(allDetections, function (d, index) {
					searchByDetectionArray.push({
						label: d["detection"],
						value: d["detection"],
					});
					suggestionsArray.push(d["event_name"]);
					suggestionsArray.push(d["source_data_indices"]);
					suggestionsArray = _.uniq(suggestionsArray);
					// console.log('suggestionsArray: ', suggestionsArray);
				});
				filteredData = allDetections;
				searchByDetectionArray = _.orderBy(
					searchByDetectionArray,
					["label"],
					["asc"]
				);
				this.setState({
					searchByDetectionArray,
					filteredData,
					totalLength: filteredData.length,
					allDetections,
					suggestionsArray,
				});
			}
		});
	}

	setPageSize(pageNumber) {
		this.setState({ pageSize: pageNumber });
	}

	renderTags(params) {
		let tagData = [];
		let row = params.row;
		// console.log("row:", row);
		let data_sources_default = typeof row["data_sources_default"] === "string" ? JSON.parse(row["data_sources_default"]) : row["data_sources_default"];
		let data_sources_optional = typeof row["data_sources_optional"] === "string" ? JSON.parse(row["data_sources_optional"]) : row["data_sources_optional"];
		let data_sources_recommended = typeof row["data_sources_recommended"] === "string" ? JSON.parse(row["data_sources_recommended"]) : row["data_sources_recommended"];
		let data_sources_required = typeof row["data_sources_required"] === "string" ? JSON.parse(row["data_sources_required"]) : row["data_sources_required"];
		let data_sources_dependency = typeof row["data_sources_dependency"] === "string" ? JSON.parse(row["data_sources_dependency"]) : row["data_sources_dependency"];

		data_sources_default.length &&
			_.map(data_sources_default, function (d) {
				tagData.push({ name: d, class: "default" });
			});
		data_sources_optional.length &&
			_.map(data_sources_optional, function (o) {
				tagData.push({ name: o, class: "optional" });
			});
		data_sources_recommended.length &&
			_.map(data_sources_recommended, function (r) {
				tagData.push({ name: r, class: "recommended" });
			});
		data_sources_required.length &&
			_.map(data_sources_required, function (req) {
				tagData.push({ name: req, class: "required" });
			});
		data_sources_dependency.length &&
			_.map(data_sources_dependency, function (dep) {
				tagData.push({ name: dep, class: "dependency" });
			});

		return (
			tagData.length &&
			tagData.map(function (d) {
				const cellValue = d.name.replace(/_/g, " ");
				return (
					<span className={"significance " + d.class}>
						{cellValue}
					</span>
				);
			})
		);
	}

	ProcessesColumns: ColDef[] = [
		{
			field: "detection",
			headerName: "Detections",
			width: 200,
		},
		{ field: "category", headerName: "Category", width: 150 },
		{
			field: "sub_category",
			headerName: "Sub Category",
			width: 200,
		},
		{
			field: "source_data_indices",
			headerName: "Source Data Indices",
			width: 200,
		},
		{
			field: "data_sources_default",
			headerName: "Data Sources",
			// width: 400,
			flex: 1,
			renderCell: (params: GridCellParams) => this.renderTags(params),
		},
	];

	render() {
		const isLoading = this.state.isLoading;
		const isSourceLoading = this.state.isSourceLoading;
		const detectionsRows = this.state.filteredData;
		// console.log("detectionsRows:", detectionsRows);
		const { pageSize } = this.state;
		function CustomToolbar() {
			return (
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
				</GridToolbarContainer>
			);
		}

		return (
			<Container className="full_height">
				{isSourceLoading && this.searchPanel()}
				{!isSourceLoading && <LoaderComponent />}
				{isLoading && <LoaderComponent />}
				{/* {_.isEmpty(resultsData) && (!isLoading && isSourceLoading) ? <NoRecords /> : <DetectionTable isMultiSelect={this.state.isMultiSelect} isSourceLoading={isSourceLoading} isLoading={isLoading} resultsData={resultsData} />} */}
				{!isLoading && <div className="resultTable" style={{ width: "100%" }}>
					<DataGrid
						// pageSize={pageSize}
						// onPageSizeChange={(newPageSize) =>
						// 	this.setPageSize(newPageSize)
						// }
						// rowsPerPageOptions={[10, 20, 30, 50, 100]}
						// pagination
						autoHeight
						autoPageSize
						getRowId={(row) => row._id}
						columns={this.ProcessesColumns}
						rows={detectionsRows}
						components={{
							Toolbar: CustomToolbar,
						}}
					/>
				</div> }
			</Container>
		);
	}
}

export default TableWithFilters;
