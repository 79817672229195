import axios from 'axios';
import { urlConstants } from '../constants';

export const commonServices = { showDetections, search, getAllDetections, getDataSources, getSourceTypes };

function showDetections(seletectedSources, version) {
    return axios.post(urlConstants.BASE_URI_DEV + '/show-detections', { 'seletectedSources':seletectedSources, 'version': version }, { headers: { "Access-Control-Allow-Origin": "*" } }).then(
        res => { // then print response status
            // console.log(res);
            return res;
        });
}

function search(term) {
    return axios.post(urlConstants.BASE_URI_DEV + '/search', { 'term': term }, { headers: { "Access-Control-Allow-Origin": "*" } }).then(
        res => { // then print response status
            // console.log(res);
            return res;
        });
}

function getAllDetections(version) {
    return axios.post(urlConstants.BASE_URI_DEV + '/get-all-detections', { 'version': version }, { headers: { "Access-Control-Allow-Origin": "*" } }).then(
        res => { // then print response status
            // console.log(res);
            return res;
        });
}

function getDataSources() {
    return axios.get(urlConstants.BASE_URI_DEV + '/get-data-sources/', { headers: { "Access-Control-Allow-Origin": "*" } }).then(
        res => { // then print response status
            // console.log(res);
            return res;
        });
}

function getSourceTypes() {
    return axios.get(urlConstants.BASE_URI_DEV + '/get-source-types/', { headers: { "Access-Control-Allow-Origin": "*" } }).then(
        res => { // then print response status
            // console.log(res);
            return res;
        });
}