import React from 'react';
import {
    Container
} from 'reactstrap';
import Logo from '../images/stellar-cyber-logo-2023.svg';
import OpenXDRLoopImage from '../images/open-xdr-graphic-illustration.svg';

function Header(props) {
    return (
        <div className="header">
            <div className="topbar">
                <Container>
                    <img src={Logo} alt="Stellar Cyber" className="company_logo" />
                </Container>
            </div>
            <div className="header_background">
                <Container>
                    <h1>Detections & Response</h1>
                    <h4>Version {props.version}</h4>
                    <img src={OpenXDRLoopImage} alt="Stellar Cyber's Open XDR" className="open-xdr-loop-img"/>
                </Container>
            </div>
        </div>
    );
}

export default Header;