import React from 'react';
import DizziRobot from '../images/dizzyrobot.png';
import { Link } from 'react-router-dom';

function UnAuthorized() {
    return (
        <div className="container full_height">
            <div className="unauthorized_container">
                <img src={DizziRobot} alt="Robot"/>
                <h2>Authentication Failed</h2>
                <h4>Please authenticate from the stellarcyber Knowledge Base.</h4>
                <h4>Or you can visit the 3.12.1 detections page</h4>
                <Link
                    className="btn btn-warning"
                    to='/v3.12.0/'>
                    Go to version 3.12.0
                </Link>
            </div>
        </div>
    );
}

export default UnAuthorized;