import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, {useState} from "react";
import TableWithFiltersLatest from './components/TableWithFiltersLatest';
import TableWithFilters from './components/TableWithFilters';
import UnAuthorized from './components/UnAuthorized';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { urlConstants } from './constants';
import Cookies from 'universal-cookie';
// import  { encrypt , decrypt } from 'react-crypt-gsm';
import _ from "lodash";

const jwt = require('jsonwebtoken');

function App() {
  let [version, setVersion] = useState('Not Identified');
  const cookies = new Cookies();
  const query = new URLSearchParams(window.location.search);
  let token = query.get('t');
  let cookieToken = cookies.get("tokenExisted");
  // console.log("cookieToken:", cookieToken);
  let newCookieToken = cookies.get("authExisted");
  let isCookieTokenVerified = false;
  // console.log("cookieToken: ", cookieToken);
  if(newCookieToken){
    let decodedToken = jwt.decode(newCookieToken, {complete: true});
    let generatedToken = jwt.sign({ header: decodedToken.header, payload: {exp:decodedToken.payload.exp}, signature: decodedToken.signature }, 'D!P?T3xhd7EwW9Veb*c-mshP_ywuXJG+cwA6mHUX6T!f3H');
    isCookieTokenVerified = jwt.verify(generatedToken, urlConstants.SECRET_KEY);
  } else if (cookieToken){
    isCookieTokenVerified = true;
  };
  
  // cookieToken = !_.isEmpty(cookieToken) ? decrypt(cookieToken) : cookieToken;
  let isTokenVerified = isCookieTokenVerified ? true : false;
  cookies.remove("tokenExisted", {path: '/', expires:'01 Jan 1970 00:00:00 UTC'});
  token = jwt.decode(token, {complete: true});
  // console.log('token: ', token);

  if(token && !isCookieTokenVerified){
    const currentDateTime = new Date();
    const tokenDate = new Date(token.payload.exp * 1000);
    var FIVE_MIN_DELAY=5*60*1000;
    let TWELVE_HOURS= new Date().getTime() + 720*60*1000;
    TWELVE_HOURS = new Date(TWELVE_HOURS);
    // console.log("TWELVE_HOURS:", TWELVE_HOURS);
    // console.log('condition: ', (currentDateTime - tokenDate) < FIVE_MIN_DELAY);
    if((currentDateTime - tokenDate) < FIVE_MIN_DELAY){ // Check token date & time with current date & time is at least 5 minutes old
      let newtoken = jwt.sign({ header: token.header, payload: {exp:token.payload.exp * 1000}, signature: token.signature }, 'D!P?T3xhd7EwW9Veb*c-mshP_ywuXJG+cwA6mHUX6T!f3H');
      isTokenVerified = jwt.verify(newtoken, urlConstants.SECRET_KEY);
      if(isTokenVerified){
        cookies.set('authExisted', newtoken, {path: '/', expires:TWELVE_HOURS});
      }
    }
  }
  // 
  // console.log('isTokenVerified: ', isTokenVerified);
  const setVersionFrom=function(version){
      setVersion(version);
  };
  
  
  return (
    <Router>
      <div>
        <Header version={version}/>
        <Switch>
          <Route path="/5.2.0"> 
            <Redirect to="/v5.2.0/" />
          </Route>
          <Route path="/v5.2.0">
            <TableWithFiltersLatest version="5.2.0" setVersion={setVersionFrom} />
          </Route>
          <Route path="/5.1.1"> 
            <Redirect to="/v5.1.1/" />
          </Route>
          <Route path="/v5.1.1">
            <TableWithFiltersLatest version="5.1.1" setVersion={setVersionFrom} />
          </Route>
          <Route path="/4.3.7"> 
            <Redirect to="/v4.3.7/" />
          </Route>
          <Route path="/v4.3.7">
            <TableWithFiltersLatest version="4.3.7" setVersion={setVersionFrom} />
          </Route>
          <Route path="/4.3.6"> 
            <Redirect to="/v4.3.5/" />
          </Route>
          <Route path="/v4.3.6/">
            <TableWithFiltersLatest version="4.3.6" setVersion={setVersionFrom} />
          </Route>
          <Route path="/4.3.5"> 
            <Redirect to="/v4.3.5/" />
          </Route>
          <Route path="/v4.3.5/">
            <TableWithFiltersLatest version="4.3.5" setVersion={setVersionFrom} />
          </Route>
          <Route path="/4.3.4">
            <Redirect to="/v4.3.4/" />
          </Route>
          <Route path="/v4.3.4/">
            <TableWithFiltersLatest version="4.3.4" setVersion={setVersionFrom} />
          </Route>
          <Route path="/4.2.0">
            <Redirect to="/v4.2.0/" />
          </Route>
          <Route path="/v4.2.0/">
            <TableWithFiltersLatest version="4.2.0" setVersion={setVersionFrom} />
          </Route>
          <Route path="/authintication-error/">
            <UnAuthorized />
          </Route>
          <Route path="/v3.12.0/">
            <TableWithFilters version="3.12" setVersion={setVersionFrom}/>
          </Route>
          <Route path="/">
            <Redirect to="/v5.2.0/" />
          </Route>
        </Switch>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
