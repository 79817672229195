import React from 'react';
import loader from '../images/loader.svg';

function LoaderComponent(props) {
    return (
        <div className="loader_background">
            <img src={loader} alt="Loader" /><span>Loading...</span>
        </div>
    );
}

export default LoaderComponent;