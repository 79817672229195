import React from 'react';

function LegendsComponent(props) {
    return (
        <div>
            <div className="legends">
                <table className="legends_table" cellPadding="2" cellSpacing="0" border="0" width="100%">
                    <tbody>
                        <tr>
                            <td><strong>Legends:</strong> </td>
                            <td><div className="color_code default"></div> Used</td>
                            <td><div className="color_code required"></div> Required</td>
                            <td><div className="color_code optional"></div> Optional</td>
                            <td><div className="color_code recommended"></div> Recommended</td>
                            <td><div className="color_code dependency"></div> Dependency</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="clearfix"/>
        </div>
    );
}

export default LegendsComponent;