import React from 'react';

function Footer(props) {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">© 2024 Stellar Cyber All Rights Reserved. <strong>Visit Website: <a href="https://stellarcyber.ai/">Stellarcyber.ai</a></strong></div>
                </div>
            </div>
        </div>
    );
}

export default Footer;